<template>
  <div
    class="d-flex flex-column flex-grow-1 mobility-layout"
    v-resize="checkIsPortrait"
  >
    <v-app-bar
      class="px-2"
      v-if="showBars"
      app
      height=42
      short
    >
      <v-app-bar-nav-icon
        v-if="$route.name !== 'mobility-home'"
        @click="$router.back()"
      >
        <v-icon>{{$icon('i.ChevronLeft')}}</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon v-else>
        <v-icon color="primary">{{$icon('i.COTCollection')}}</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title
        v-if="currentModule"
        class="ml-2 pl-0"
      >
        <component
          v-if="currentModule.componentName"
          :document="currentModule.data"
          :options="currentModule.options"
          v-bind:is="currentModule.componentName"
        />
        <div
          v-else
          class="d-flex flex-row align-center"
        >
          <v-icon
            v-if="currentModule.icon"
            class="mr-2 inherit-color"
          >{{$icon(currentModule.icon)}}</v-icon>
          <span class="font-weight-bold text-truncate overflow-hidden">{{$t($store.getters.module.name)}}</span>
        </div>
      </v-toolbar-title>
    </v-app-bar>
    <v-main class="main background-plain">
      <v-container fluid>
        <transition :name="transitionName">
          <keep-alive
            max="5"
            :exclude="['login', 'mobility-cgu']"
          >
            <router-view :key="$route.name+$route.params.id?$route.params.id:''" />
          </keep-alive>
        </transition>
      </v-container>
    </v-main>
    <v-bottom-navigation
      v-if="showBars"
      app
      grow
      height=42
      :value="false"
    >
      <v-btn
        @click="navigateTo('mobility-home')"
        max-width="100%"
      >
        <span>{{$t('t.Home')}}</span>
        <v-icon>{{$icon('i.Home')}}</v-icon>
      </v-btn>
      <v-btn
        @click="navigateTo('mobility-profile')"
        max-width="100%"
      >
        <span>{{$t('t.MyAccount')}}</span>
        <user-ref
          :id="$store.getters.currentUser.id"
          :attach="false"
          :avatar-props="{xsmall: true}"
          block-popup
          hide-email
          hide-fullname
          hide-group
          hide-phone
        />
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import themeController from '@/themeController'

export default {
  components: {
    AccountHeader: () => import('@/pages/account/account-header'),
    UserRef: () => import('@/components/documents-ref/user-ref')
  },
  computed: {
    currentModule () { return this.$store.getters.module },
    themeController () { return themeController },
    showBars () { return !['mobility-login', 'mobility-cgu'].includes(this.$route.name) }
  },
  data () {
    return {
      transitionName: 'mobility-next-page',
      viewport: { isPortrait: true, isMobile: true }
    }
  },
  provide () {
    return {
      viewport: this.viewport
    }
  },
  methods: {
    checkIsPortrait () {
      this.viewport.isPortrait = window.matchMedia('(orientation: portrait)').matches
    },
    navigateTo (name) {
      if (this.$route.name !== name) {
        this.$router.push({ name })
      }
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.transitionName = to.meta.level < from.meta.level ? 'mobility-previous-page' : 'mobility-next-page'
    next()
  }
}
</script>

<style lang="stylus">
html
  font-size 12px
</style>

<style lang="stylus" scoped>
.mobility-layout
  position fixed
  width 100%
  height 100%

.container
  height 100%

.main
  height 100%

  >>>.v-main__wrap
    overflow-x hidden
    overflow-y auto
    overscroll-behavior-y contain

// Transition
.mobility-next-page-enter-active, .mobility-previous-page-enter-active
  transition all 0.2s !important

.mobility-next-page-leave-active, .mobility-previous-page-leave-active
  transition all 0s !important

.mobility-next-page-enter, .mobility-next-page-leave-to, .mobility-previous-page-enter, .mobility-previous-page-leave-to
  opacity 0

.mobility-next-page-enter
  transition-delay 2s !important
  transform translateX(100vw)

.mobility-next-page-leave-to
  transform translateX(-100vw)

.mobility-previous-page-enter
  transition-delay 2s !important
  transform translateX(-100vw)

.mobility-previous-page-leave-to
  transform translateX(100vw)
</style>
